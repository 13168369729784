<template>
    <div ref="cesium" class="h-100"></div>
</template>

<script>
import { CesiumMap } from '@/libs'
export default {
    data () {
      return {
      }
    },
    async mounted() {
        // 加载cesium
        const cesium = await new CesiumMap(this.$refs.cesium, { isCredit: true, isAnimation: true })
        this.cesium = cesium
    }
}
</script>

<style lang="less" scoped>
</style>